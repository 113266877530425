export const CATEGORY_URL = 'collections/';
export const PRODUCT_URL = 'products/';
export const PAGES_URL = 'pages/';

export const IGNORE_REDIRECTION_URLS = [
  'invisible-braces',
  'about',
  'website-terms-and-conditions',
  'privacy',
  'dentist',
  'referrals-mechanism',
  'referrals',
  'csat-survey-sales',
  'csat-survey-nps',
  'csat-survey-clinic',
  'consumer_protection_policy',
  'faqs',
  'refund-policy',
  'warranty-device-details',
  'warranty-activation',
  'misplaced-warranty-card',
  'invalid-serial-number',
  'misplaced-warranty-card/success',
  'invalid-serial-number/success',
  'how-to-find-serial-number'
];

export const RESTRICTED_PAGES = [
  'checkout-page',
  'checkout-login',
  'account-details',
  'account-orders',
  'add-address',
  'klook-landing',
  'orders',
  'reset',
  'reset-password',
  'checkout/shipping',
  'order-summary',
  'checkout'];

const END_POINTS = {
  CART: 'cart',
  PRIVACY: 'privacy',
  SHOPALL: 'shopall',
  TERMS_AND_CONDTIONS: 'website-terms-and-conditions',
  TOOTHPASTE_AND_MOUTHWASH: 'toothpaste-and-mouthwash',
  WATERFLOSSERPRO_DETAILS: 'waterflosserpro-details',
  WATERFLOSSERPRO_TIP_DETAILS: 'waterflosserpro-tips-details',
  ZENFRESH_BREATHSHIELD_DETAILS: 'zenfresh-breathshield-details',
  ZENFRESH_BUNDLE_DETAILS: 'zenfresh-bundle-details',
  ZENFRESH_FLOSS_DETAILS: 'zenfresh-floss-details',
  ZENFRESH_TOOTHPASTE_DAY: 'zenfresh-toothpaste-day',
  ZENFRESH_TOOTHPASTE_NIGHT: 'zenfresh-toothpaste-night',
  ZENYUM_FRESH_FLOSS: 'zenyumfresh-floss',
  ZENYUM_POWER_CLEAN_BUNDLE: 'zenyum-power-clean-bundle-waterflosser-pro-zenyumsonic-toothbrush',
  ZENYUM_REFILLS: 'zenyum-refills',
  ZENYUM_WATERFLOSSER_PRO: 'zenyum-waterflosser-pro',
  ZENYUM_WATERFLOSSER_PRO_STANDARD_JET_TIPS_REFILL_PACK: 'zenyum-waterflosser-pro-standard-jet-tips-refill-pack',
  ZENYUM_WATERFLOSSERPRO: 'zenyum-waterflosser-pro',
  ZENYUMBRIGHT_INVISIBLE_WHITENING_FOURTEEN_STRIPS: 'zenyumbright-invisible-whitening-strips-14-strips',
  ZENYUMBRIGHT_TEETH_WHITENING_STRIPS: 'zenyumbright-teeth-whitening-strips',
  ZENYUMBRIGHT_TEETH_WHITENING_STRIPS_DETAILS: 'zenyumbright-teeth-whitening-strips-details',
  ZENYUMFLOSS: 'zenyumfloss',
  ZENYUMFRESH_BREATH_SHIELD: 'zenyumfresh-breath-shield',
  ZENYUMFRESH_BUNDLE: 'zenyumfresh-bundle',
  ZENYUMFRESH_TOOTHPASTE: 'zenyumfresh-toothpaste',
  ZENYUMFRESH_TOOTHPASTE_DAY_NIGHT: 'zenyumfresh-toothpaste-day-night',
  ZENYUMSONIC_BRUSH_HEAD: 'zenyumsonic-brush-head',
  ZENYUMSONIC_BRUSH_HEAD_THREE_PACK: 'zenyumsonic-brush-head-3-pack',
  ZENYUMSONIC_DETAILS: 'zenyumsonic-details',
  ZENYUMSONIC_ELECTRIC_TOOTHBRUSHES: 'zenyumsonic-electric-toothbrushes',
  ZENYUMSONIC_GENTLE_BRUSH_HEAD: 'zenyumsonic-gentle-brush-head',
  ZENYUMSONIC_GENTLE_CLEAN_BRUSH_HEAD_TWO_PACK: 'zenyumsonic-refill-gentle-clean-brush-head-2-pack',
  ZENYUMSONIC_GENTLE_CLEAN_BRUSH_HEAD_FOUR_PACK: 'zenyumsonic-refill-gentle-clean-brush-head-4-pack',
  ZENYUMSONIC_GO_BRUSH_HEAD_THREE_PACK: 'zenyumsonic-go-brush-head-3-pack',
  ZENYUMSONIC_GO_TOOTHBRUSH: 'zenyumsonic-go-toothbrush',
  ZENYUMSONIC_GO_TRAVEL_SET: 'zenyumsonic-go-travel-set',
  ZENYUMSONIC_REFILL_GENTLE_CLEAN_BRUSH_HEAD_TWO_PACK: 'zenyumsonic-refill-gentle-clean-brush-head-2-pack',
  ZENYUMSONIC_STORY: 'zenyumsonic-story',
  ZENYUMSONIC_TOOTHBRUSH: 'zenyumsonic-toothbrush',
  ZENYUMSONIC_TOOTHBRUSH_AND_BRUSH_HEAD_THREE_PACK: 'zenyumsonic-toothbrush-and-brush-head-3-pack',
  ZENYUMSONICGO_BRUSH_HEAD: 'zenyumsonicgo-brush-head',
  ZENYUMSONICGO_DETAILS: 'zenyumsonicgo-details',
  ZENYUMSONICGO_POKEMON_COLLECTION: 'zenyumsonic-go-pokemon-collection',
  ZENYUMSONIC_GENTLE_STARTER_KIT: 'zenyumsonic-gentle-starter-kit'
};

export const REDIRECTION_URL_MAP = {
  cart: `${END_POINTS.CART}`,
  shopall: `${PAGES_URL}${END_POINTS.SHOPALL}`,
  'toothpaste-and-mouthwash': `${CATEGORY_URL}${END_POINTS.TOOTHPASTE_AND_MOUTHWASH}`,
  'waterflosserpro-details': `${PRODUCT_URL}${END_POINTS.ZENYUM_WATERFLOSSERPRO}`,
  'waterflosserpro-tips-details': `${PRODUCT_URL}${END_POINTS.ZENYUM_WATERFLOSSER_PRO_STANDARD_JET_TIPS_REFILL_PACK}`,
  'zenfresh-breathshield-details': `${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_BREATH_SHIELD}`,
  'zenfresh-bundle-details': `${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_BUNDLE}`,
  'zenfresh-floss-details': `${PRODUCT_URL}${END_POINTS.ZENYUM_FRESH_FLOSS}`,
  'zenfresh-toothpaste-day': `${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_TOOTHPASTE}`,
  'zenfresh-toothpaste-night': `${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_TOOTHPASTE_DAY_NIGHT}`,
  'zenyum-refills': `${CATEGORY_URL}${END_POINTS.ZENYUM_REFILLS}`,
  'zenyumbright-teeth-whitening-strips': `${CATEGORY_URL}${END_POINTS.ZENYUMBRIGHT_TEETH_WHITENING_STRIPS}`,
  'zenyumbright-teeth-whitening-strips-details':
   `${PRODUCT_URL}${END_POINTS.ZENYUMBRIGHT_INVISIBLE_WHITENING_FOURTEEN_STRIPS}`,
  zenyumfloss: `${CATEGORY_URL}${END_POINTS.ZENYUMFLOSS}`,
  'zenyumsonic-brush-head': `${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_BRUSH_HEAD_THREE_PACK}`,
  'zenyumsonic-details': `${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_TOOTHBRUSH}`,
  'zenyumsonic-electric-toothbrushes': `${CATEGORY_URL}${END_POINTS.ZENYUMSONIC_ELECTRIC_TOOTHBRUSHES}`,
  'zenyumsonic-gentle-brush-head': `${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_REFILL_GENTLE_CLEAN_BRUSH_HEAD_TWO_PACK}`,
  'zenyumsonicgo-brush-head': `${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_GO_BRUSH_HEAD_THREE_PACK}`,
  'zenyumsonicgo-details': `${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_GO_TOOTHBRUSH}`
};

export default END_POINTS;

/* eslint-disable max-len */
import { useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import CountrySelector from './country-selector';
import DisclaimerModal from './disclaimer-modal';

import { buttonClickEvent } from '../../helpers/segment';
import useI18n from '../../hooks/use-i18n';
import useUtmParam from '../../hooks/useUtmParams';
import { countryMap } from '../../language.config';
import { externalLinks, faqDisabledMarkets, freshMarkets,
  socialLinks, sonicGoMarkets, sonicMarkets, waterflosserMarkets,
  whiteningMarkets, cartDisabledMarkets, sonicGentleBrushHeadMarkets } from '../../links.config';
import END_POINTS, { CATEGORY_URL, PAGES_URL } from '../../utils/shopify-endpoints';
import { SHOPIFY_URLS } from '../../utils/utils';

import '../../styles/revamp/footer.scss';
import '../../styles/revamp/disclaimer-modal.scss';

export default function Footer({ page }) {
  const i18n = useI18n();
  const footer = i18n.t('footer');
  const contact = i18n.t('contact');
  const COUNTRY_LANGUAGE = i18n.activeLocale;
  const codes = COUNTRY_LANGUAGE.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  const lang = codes[1] || 'en';
  const isSgMyHk = country === 'sg' || country === 'my' || country === 'hk';
  const router = useRouter();
  const query = router && router.query;
  const socialMedia = socialLinks[country] || socialLinks.sg;
  let socialMediaIcons = {
    // google: socialMedia.gplus,
    youtube: socialMedia.youtube,
    fb: socialMedia.facebook,
    // twitter: socialMedia.twitter,
    ig: socialMedia.instagram,
    tiktok: socialMedia.tiktok
  };

  if (country === 'tw') {
    socialMediaIcons = {
      youtube: socialMedia.youtube,
      fb: socialMedia.facebook,
      ig: socialMedia.instagram,
      line: socialMedia.line
    };
  }

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const onModalClose = () => {
    setShowDisclaimer(false);
  };

  const onModalOpen = () => {
    setShowDisclaimer(true);
  };

  const buttonClick = (btnId, btnName) => {
    buttonClickEvent(
      btnId,
      btnName,
      'Footer',
      '',
      btnName,
      countryMap[country]
    );
  };

  return (
    <footer>
      <section className="footer-top">
        <div className="cds--grid">
          <div className="cds--row">
            {(country !== 'jp' && country !== 'th' && country !== 'id') && (
            <div
              className="cds--col cds--col-sm-4 cds--col-max-2 cds--col-xlg-3 cds--col-lg-3 cds--col-md-3 footer-sec"
            >
              <h4>{footer.footer_head_1}</h4>
              <ul className="footer-list">
                <li>
                  <a
                    data-lokalise=""
                    data-key="footer::shop_link_1"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/invisible-braces`}
                    onClick={() => { buttonClick('footer.shop_link_1', footer.shop_link_1); }}
                    id="footer-shop_link_1"
                  >
                    {footer.shop_link_1}
                  </a>
                </li>
                {whiteningMarkets.includes(country) && (
                  <li>
                    <a
                      href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.ZENYUMBRIGHT_TEETH_WHITENING_STRIPS}`}
                      referrerPolicy="no-referrer"
                      rel="noreferrer"
                      data-lokalise=""
                      data-key="footer::shop_link_6"
                      onClick={() => { buttonClick('footer.shop_link_6', footer.shop_link_6); }}
                      id="footer-shop_link_6"
                      target="_blank"
                    >
                      {footer.shop_link_6}
                    </a>
                  </li>
                )}
                {(sonicMarkets.includes(country) || sonicGoMarkets.includes(country)) && (
                  <li>
                    <a
                      href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.ZENYUMSONIC_ELECTRIC_TOOTHBRUSHES}`}
                      referrerPolicy="no-referrer"
                      rel="noreferrer"
                      data-lokalise=""
                      data-key="footer::shop_link_2"
                      onClick={() => { buttonClick('footer.shop_link_2', footer.shop_link_2); }}
                      id="footer-shop_link_2"
                      target="_blank"
                    >
                      {footer.shop_link_2}
                    </a>
                  </li>
                )}
                {freshMarkets.includes(country) && (
                  <li>
                    <a
                      href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.TOOTHPASTE_AND_MOUTHWASH}`}
                      referrerPolicy="no-referrer"
                      rel="noreferrer"
                      data-lokalise=""
                      data-key="footer::shop_link_3"
                      onClick={() => { buttonClick('footer.shop_link_3', footer.shop_link_3); }}
                      id="footer-shop_link_3"
                      target="_blank"
                    >
                      {footer.shop_link_3}
                    </a>
                  </li>
                )}
                {waterflosserMarkets.includes(country) && (
                <li>
                  <a
                    href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.ZENYUMFLOSS}`}
                    referrerPolicy="no-referrer"
                    rel="noreferrer"
                    data-lokalise=""
                    data-key="footer::shop_link_4"
                    onClick={() => { buttonClick('footer.shop_link_4', footer.shop_link_4); }}
                    id="footer-shop_link_4"
                    target="_blank"
                  >
                    {footer.shop_link_4}
                  </a>
                </li>
                )}
                {sonicGentleBrushHeadMarkets.includes(country) && (
                <li>
                  <a
                    href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.ZENYUM_REFILLS}`}
                    referrerPolicy="no-referrer"
                    rel="noreferrer"
                    data-lokalise=""
                    data-key="footer::shop_link_7"
                    onClick={() => { buttonClick('footer.shop_link_7', footer.shop_link_7); }}
                    id="footer-shop_link_7"
                    target="_blank"
                  >
                    {footer.shop_link_7}
                  </a>
                </li>
                )}
                {sonicMarkets.includes(country) && (
                <li>
                  <a
                    href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PAGES_URL}${END_POINTS.SHOPALL}`}
                    referrerPolicy="no-referrer"
                    rel="noreferrer"
                    data-lokalise=""
                    data-key="footer::shop_link_5"
                    onClick={() => { buttonClick('footer.shop_link_5', footer.shop_link_5); }}
                    id="footer-shop_link_5"
                    target="_blank"
                  >
                    {footer.shop_link_5}
                  </a>
                </li>
                )}
              </ul>
            </div>
            )}

            <div
              className="cds--col cds--col-sm-4 cds--col-max-2 cds--col-xlg-2 cds--col-lg-2 cds--col-md-3 footer-sec"
            >
              <a
                data-lokalise=""
                data-key="footer::footer_head_2"
                href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about`}
              >
                <h4>{footer.footer_head_2}</h4>
              </a>
              <ul className="footer-list">
                <li>
                  <a
                    data-lokalise=""
                    data-key="footer::about_revamp_link_1"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-whatwedo`}
                  >
                    {footer.about_revamp_link_1}
                  </a>
                </li>
                <li>
                  <a
                    data-lokalise=""
                    data-key="footer::about_revamp_link_2"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-story`}
                  >
                    {footer.about_revamp_link_2}
                  </a>
                </li>
                <li>
                  <a
                    data-lokalise=""
                    data-key="footer::about_revamp_link_3"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-journey`}
                  >
                    {footer.about_revamp_link_3}
                  </a>
                </li>
                <li>
                  <a
                    data-lokalise=""
                    data-key="footer::about_revamp_link_4"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-partner`}
                  >
                    {footer.about_revamp_link_4}
                  </a>
                </li>
                <li>

                  <a
                    data-lokalise=""
                    data-key="footer::about_revamp_link_5"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-highlight`}
                  >
                    {footer.about_revamp_link_5}
                  </a>
                </li>
                {country !== 'th' && (
                <li>
                  <a
                    data-lokalise=""
                    data-key="footer::about_revamp_link_6"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-community`}
                  >
                    {footer.about_revamp_link_6}
                  </a>
                </li>
                )}
                {!cartDisabledMarkets.includes(country) && (
                <li>
                  <a
                    data-lokalise=""
                    data-key="footer::about_revamp_link_7"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-join`}
                  >
                    {footer.about_revamp_link_7}
                  </a>
                </li>
                )}
              </ul>
            </div>
            <div
              className="cds--col cds--col-sm-4 cds--col-max-2 cds--col-xlg-3 cds--col-lg-3 cds--col-md-2 footer-sec"
            >
              <h4>{footer.footer_head_3}</h4>
              <ul className="footer-list">
                {!faqDisabledMarkets.includes(country) && (
                <li>
                  <a
                    data-lokalise=""
                    data-key="footer::discover_link_1"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/faqs`}
                    onClick={() => { buttonClick('footer.discover_link_1', footer.discover_link_1); }}
                    id="footer-discover_link_1"
                  >
                    {footer.discover_link_1}
                  </a>
                </li>
                )}
                <li>
                  <a
                    data-lokalise=""
                    data-key="footer::discover_link_1"
                    href={`${country !== 'hk'
                      ? externalLinks[country].blog : externalLinks[country][lang].blog}${useUtmParam(query)}`}
                    onClick={() => { buttonClick('footer.discover_link_2', footer.discover_link_2); }}
                    id="footer-discover_link_2"
                  >
                    {footer.discover_link_2}
                  </a>
                </li>
                <li>
                  <a
                    data-lokalise=""
                    data-key="footer::discover_link_3"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/dentist`}
                    onClick={() => { buttonClick('footer.discover_link_3', footer.discover_link_3); }}
                    id="footer-discover_link_3"
                  >
                    {footer.discover_link_3}
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="cds--col cds--col-sm-4 cds--col-max-4 cds--col-xlg-4 cds--col-lg-4 cds--col-md-4 footer-sec"
            >
              <h4>{footer.footer_head_4}</h4>
              {contact[country].line_name && country === 'jp' && (
                <p>
                  <a href={contact[country].line_link}>
                    <img data-gumlet="false" src="/img/line-icon.svg" alt="line" />
                    &nbsp;
                    {contact[country].line_name}
                  </a>
                </p>
              )}
              <p className="text-break">
                <img src="/img/icon-email.svg" data-gumlet="false" alt="Email" />
                &nbsp;
                <a
                  href={contact[country].email_link ? `mailto:${contact[country].email_link}`
                    : `mailto:${contact[country].email}`}
                >
                  {contact[country].email}
                </a>
              </p>
              {contact[country].whatsapp && (
                <p>
                  {(country !== 'tw' && country !== 'vn') && (
                    <>
                      <img src="/img/icon-whatsapp.svg" data-gumlet="false" alt="Whatsapp" />
                      &nbsp;
                    </>
                  )}
                  {country === 'vn' && (
                    <>
                      <img src="/img/zalo-icon.png" data-gumlet="false" alt="Zalo" />
                      &nbsp;
                    </>
                  )}
                  <a href={contact[country].whatsapp_link}>{contact[country].whatsapp}</a>
                </p>
              )}
              {contact[country].tel && contact[country].tel !== 'nil' && country !== 'hk' && (
              <p>
                <img src="/img/icon-phone.svg" data-gumlet="false" alt="Tel" />
                &nbsp;
                <a href={`tel:${contact[country].tel}`}>{contact[country].tel}</a>
              </p>
              )}
              {contact[country].line_name && country !== 'jp' && (
                <p>
                  <a href={contact[country].line_link}>
                    <img data-gumlet="false" src="/img/line-icon.svg" alt="line" />
                    &nbsp;
                    {contact[country].line_name}
                  </a>
                </p>
              )}
              {contact[country].address && (
                <p>
                  <img src="/img/icon-location.svg" data-gumlet="false" alt="Tel" />
                  &nbsp;
                  {contact[country].address}
                </p>
              )}
            </div>
            {isSgMyHk && (
              <div
                className="cds--col cds--col-sm-4 cds--col-max-4 cds--col-xlg-4 cds--col-lg-4 cds--col-md-4 footer-sec"
              >
                <h4>{footer.footer_head_5}</h4>
                <p className="text-break">
                  <img src="/img/icon-email.svg" data-gumlet="false" alt="Email" />
                &nbsp;
                  <a
                    href={contact[country].others_email_link ? `mailto:${contact[country].others_email_link}`
                      : `mailto:${contact[country].others_email}`}
                  >
                    {contact[country].others_email}
                  </a>
                </p>
                <p>
                  <img src="/img/icon-whatsapp.svg" data-gumlet="false" alt="Whatsapp" />
                &nbsp;
                  <a href={contact[country].others_whatsapp_link}>{contact[country].others_whatsapp}</a>
                </p>
                {contact[country].line_name_other && (
                <p>
                  <a href={contact[country].line_link_other}>
                    <img data-gumlet="false" src="/img/line-icon.svg" alt="line" />
                    &nbsp;
                    {contact[country].line_name_other}
                  </a>
                </p>
                )}
                {contact[country].tel_other && (
                <p>
                  <img src="/img/icon-phone.svg" data-gumlet="false" alt="Tel" />
                  &nbsp;
                  <a href={`tel:${contact[country].tel_other}`}>{contact[country].tel_other}</a>
                </p>
                )}
                {contact[country].address && (
                <p>
                  <img src="/img/icon-location.svg" data-gumlet="false" alt="Tel" />
                  &nbsp;
                  {contact[country].address}
                </p>
                )}
              </div>
            )}
            <div className="cds--col cds--col-sm-4 cds--col-max-2 ml-auto">
              <div className="social-media-icons">
                {Object.keys(socialMediaIcons).map((icon) => (
                  socialMediaIcons[icon] && (
                  <a href={socialMediaIcons[icon]} target="_blank" key={icon} rel="noreferrer">
                    <img className="icons" data-gumlet="false" src={`/img/${icon}.svg`} alt="icon" />
                  </a>)))}
                {country === 'vn' && (
                  <a
                    href="http://online.gov.vn/Home/WebDetails/64526"
                    rel="noreferrer"
                    target="_blank"
                    className="footer-logo-vn"
                  >
                    <img src="/img/footer-logo-vn.png" data-gumlet="false" alt="icon" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer-bottom">
        <div className="cds--grid">
          <div className="cds--row">
            <div className="cds--col cds--col-sm-4 cds--col-max-5 cds--col-xlg-6">
              <CountrySelector locale={i18n.activeLocale} />
            </div>
            <div className="cds--col cds--col-sm-4 cds--col-max-11 cds--col-xlg-10 footer-bottom-right">
              {country !== 'jp' ? (
                <p>
                  <Link href="/[lng]/refund-policy" as={`/${i18n.activeLocale}/refund-policy`} prefetch={false}>
                    <a
                      role="presentation"
                      onClick={() => buttonClickEvent('footer.refund_policy', 'Refund policy',
                        'Footer', page, footer.refund_policy, countryMap[country])}
                      id="footer-refundpolicy-link"
                    >
                      {footer.refund_policy}

                    </a>
                  </Link>
                </p>
              ) : (
                <p>
                  <Link
                    href="/[lng]/legal/specified-commercial-transactions"
                    as={`/${i18n.activeLocale}/legal/specified-commercial-transactions`}
                    prefetch={false}
                  >
                    <a
                      role="presentation"
                      id="footer-commerciallaw-link"
                    >
                      {footer.commerciallaw_text}
                    </a>
                  </Link>
                </p>
              )}
              <p>
                <Link href="/[lng]/privacy" as={`/${i18n.activeLocale}/privacy`} prefetch={false}>
                  <a
                    role="presentation"
                    onClick={() => buttonClickEvent('footer.privacy_policy', 'Privacy policy',
                      'Footer', page, footer.privacy_policy, countryMap[country])}
                    id="footer-privacypolicy-link"
                  >
                    {footer.privacy_policy}
                  </a>
                </Link>
              </p>
              <p
                className="disclaimer-link"
                role="presentation"
                onClick={onModalOpen}
              >
                {footer.disclaimer}
              </p>
              <p>
                <Link
                  href="/[lng]/website-terms-and-conditions"
                  as={`/${i18n.activeLocale}/website-terms-and-conditions`}
                  id="footer-termsconditions-link"
                  prefetch={false}
                >
                  <a
                    role="presentation"
                    onClick={() => buttonClickEvent('footer.terms_conditions', 'Terms & Conditions',
                      'Footer', page, footer.terms_conditions, countryMap[country])}
                  >
                    {footer.terms_conditions}

                  </a>
                </Link>
              </p>
              <p id="footer-copyrights-text">{footer.rights_reserved}</p>

            </div>
          </div>
        </div>
        <div className="cds--grid">
          <section className="iso-container">
            <div className="iso-logo-container">
              <img src="/img/bsi_white.svg" data-gumlet="false" alt="ISO Logo" />
            </div>
            <p data-key="footer::iso_description" data-lokalise="">{footer.iso_description}</p>
          </section>
        </div>
        <div className="cds--grid">
          <div className="mobile-footer-bottom-right">
            {country !== 'jp' ? (
              <p>
                <Link href="/[lng]/refund-policy" as={`/${i18n.activeLocale}/refund-policy`} prefetch={false}>
                  <a
                    role="presentation"
                    onClick={() => buttonClickEvent('footer.refund_policy', 'Refund policy',
                      'Footer', page, footer.refund_policy, countryMap[country])}
                    id="footer-refundpolicy-link"
                  >
                    {footer.refund_policy}

                  </a>
                </Link>
              </p>
            ) : (
              <p>
                <Link
                  href="/[lng]/legal/specified-commercial-transactions"
                  as={`/${i18n.activeLocale}/legal/specified-commercial-transactions`}
                  prefetch={false}
                >
                  <a
                    role="presentation"
                    id="footer-commerciallaw-link"
                  >
                    {footer.commerciallaw_text}
                  </a>
                </Link>
              </p>
            )}
            <p>
              <Link href="/[lng]/privacy" as={`/${i18n.activeLocale}/privacy`} prefetch={false}>
                <a
                  role="presentation"
                  onClick={() => buttonClickEvent('footer.privacy_policy', 'Privacy policy',
                    'Footer', page, footer.privacy_policy, countryMap[country])}
                  id="footer-privacypolicy-link"
                >
                  {footer.privacy_policy}
                </a>
              </Link>
            </p>
            <p
              className="disclaimer-link"
              role="presentation"
              onClick={onModalOpen}
            >
              {footer.disclaimer}
            </p>
            <p>
              <Link
                href="/[lng]/website-terms-and-conditions"
                as={`/${i18n.activeLocale}/website-terms-and-conditions`}
                id="footer-termsconditions-link"
                prefetch={false}
              >
                <a
                  role="presentation"
                  onClick={() => buttonClickEvent('footer.terms_conditions', 'Terms & Conditions',
                    'Footer', page, footer.terms_conditions, countryMap[country])}
                >
                  {footer.terms_conditions}

                </a>
              </Link>
            </p>
            <p id="footer-copyrights-text">{footer.rights_reserved}</p>
          </div>
        </div>
      </section>

      {showDisclaimer && (
      <DisclaimerModal onClose={onModalClose} />
      )}
    </footer>
  );
}

Footer.propTypes = {
  page: PropTypes.string
};

Footer.defaultProps = {
  page: ''
};

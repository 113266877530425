/* eslint-disable react/no-danger */
import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import useI18n from '../../hooks/use-i18n';
import '../../styles/revamp/disclaimer-modal.scss';

export default function DisclaimerModal({ onClose }) {
  const i18n = useI18n();
  const footer = i18n.t('footer');
  const modalRef = useRef();

  useEffect(() => {
    document.body.style.paddingRight = `${window.innerWidth - document.body.scrollWidth + 2}px`;
    document.body.style.overflowY = 'hidden';
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.body.style.overflowY = 'auto';
      document.body.style.paddingRight = 0;
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (modalRef.current && modalRef.current.contains(e.target)) {
      return;
    }
    onClose();
  };

  return (
    <div className="disclaimer-modal">
      <div className="disclaimer-modal-content" ref={modalRef}>
        <div className="disclaimer-modal-header">
          <p>{footer.disclaimer}</p>
          <a className="close-btn" role="presentation" onClick={onClose}>
            <img data-gumlet="false" src="/img/updated/footer/close.svg" alt="close" />
          </a>
        </div>
        <div className="disclaimer-modal-body">
          {footer.disclaimer_text}
        </div>
      </div>
    </div>
  );
}

DisclaimerModal.propTypes = {
  onClose: PropTypes.func
};

DisclaimerModal.defaultProps = {
  onClose: () => { }
};

import { useEffect, useState } from 'react';

import { getCookie } from '../utils/storage';

export default function useUtmParam(param) {
  const [searchQuery, setSearchQuery] = useState('');
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'gclid'];
  useEffect(() => {
    let query = '?';
    utmParams.forEach((utm) => {
      if (getCookie(utm)) {
        query = `${query}${utm}=${getCookie(utm)}&`;
      }
    });
    setSearchQuery(query);
  }, [param]);
  return searchQuery && searchQuery.slice(0, -1);
}

/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import { useState, useEffect, useRef } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { countryLanguageChanged, countryLanguageClicked } from '../../helpers/segment';
import useI18n from '../../hooks/use-i18n';
import { countryLanguageList, countryMap } from '../../language.config';

import '../../styles/revamp/country-popup.scss';

export default function CountrySelector({ locale }) {
  const [isOpen, toggleIsOpen] = useState(false);
  const popup = useRef();
  const router = useRouter();
  const i18n = useI18n();
  const codes = i18n.activeLocale.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  useEffect(() => {
    toggleIsOpen(false);
  }, [locale]);
  const pathName = router.pathname.split('/')[2] || '';
  const footer = i18n.t('footer');

  useEffect(() => {
    if (isOpen) {
      countryLanguageClicked(countryMap[country]);
    }
  }, [isOpen]);

  return (
    <>
      <div className="country-wrapper" role="presentation" ref={popup} onClick={() => toggleIsOpen(!isOpen)}>
        <p>
          <img src="/img/updated/footer/country.svg" alt="country" data-gumlet="false" className="location-icon" />
          {countryLanguageList[locale]
            ? (
              <>
                <span>{countryLanguageList[locale].country}</span>
                {countryLanguageList[locale].language}
              </>)
            : (
              <>
                <span>Singapore</span>
                English
              </>)}
        </p>
      </div>
      {isOpen && (
      <div className="country-modal-overlay">
        <div className="country-modal-wrapper">
          <div className="country-modal-content">
            <a className="close-icon" onClick={() => toggleIsOpen(false)} role="presentation">
              <img src="/img/updated/footer/close.svg" alt="close footer" data-gumlet="false" />
            </a>
            <h3>{footer.country_popup_head}</h3>
            <div className="country-list-wrapper">
              <ul>
                {Object.entries(countryLanguageList).map(([key, data]) => (
                  <li className={`cds--col-max-4 cds--col-lg-4 cds--col-md-2 cds--col-sm-2 ${key === locale ? 'active' : ''}`} key={key}>
                    <a
                      referrerPolicy="no-referrer"
                      href={`${process.env.NEXT_PUBLIC_BASE_URL}${key}/${pathName}`}
                      onClick={() => { countryLanguageChanged(data.country); }}
                    >
                      <h5>{data.country}</h5>
                      <p>{data.language}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
}

CountrySelector.propTypes = {
  locale: PropTypes.string
};

CountrySelector.defaultProps = {
  locale: 'sg-en'
};
